import React from "react"
import styled, { ThemeProvider } from "styled-components"

import GlobalStyle from "theme/GlobalStyle"
import { theme } from "theme/MainTheme"

const Wrapper = styled.div`
  overflow-x: hidden;
`

const MainLayout = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Wrapper>{children}</Wrapper>
    </ThemeProvider>
  )
}

export default MainLayout

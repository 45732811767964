import { createGlobalStyle } from "styled-components"
import reset from "styled-reset"

//Assets
import futuraBookWoof from "assets/fonts/FuturaBT-Book.woff"
import futuraBookWoof2 from "assets/fonts/FuturaBT-Book.woff2"
import futuraBookItalicWoof from "assets/fonts/FuturaBT-BookItalic.woff"
import futuraBookItalicWoof2 from "assets/fonts/FuturaBT-BookItalic.woff2"
import futuraMediumWoof from "assets/fonts/FuturaBT-Medium.woff"
import futuraMediumWoof2 from "assets/fonts/FuturaBT-Medium.woff2"
import futuraMediumItalicWoof from "assets/fonts/FuturaBT-MediumItalic.woff"
import futuraMediumItalicWoof2 from "assets/fonts/FuturaBT-MediumItalic.woff2"

const GlobalStyle = createGlobalStyle`
    @font-face {
        font-family: 'futura-book';
        src: url(${futuraBookWoof2}) format('woff2'),
            url(${futuraBookWoof}) format('woff');
        font-weight: normal;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'futura-book-italic';
        src: url(${futuraBookItalicWoof2}) format('woff2'),
            url(${futuraBookItalicWoof}) format('woff');
        font-weight: normal;
        font-style: italic;
        font-display: swap;
    }

    @font-face {
        font-family: 'futura-medium';
        src: url(${futuraMediumWoof}) format('woff2'),
            url(${futuraMediumWoof2}) format('woff');
        font-weight: 500;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'futura-medium-italic';
        src: url(${futuraMediumItalicWoof}) format('woff2'),
            url(${futuraMediumItalicWoof2}) format('woff');
        font-weight: 500;
        font-style: italic;
        font-display: swap;
    }

    /* @font-face {
        font-family: 'futura-light';
        src: url('FuturaBT-Light.woff2') format('woff2'),
            url('FuturaBT-Light.woff') format('woff');
        font-weight: 300;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'futura-light-italic';
        src: url('FuturaBT-LightItalic.woff2') format('woff2'),
            url('FuturaBT-LightItalic.woff') format('woff');
        font-weight: 300;
        font-style: italic;
        font-display: swap;
    }
    */

    ${reset}
  
    body, html {
        font-family: 'futura-book', sans-serif;
    }

    a {
        text-decoration: none;
    }

    *, *::before, *::after {
        box-sizing: border-box;
        border: 0;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        -webkit-tap-highlight-color: transparent;
        outline: 0 !important;
    }

    @keyframes pulse {
        0% {
            transform: scale(0.95);
            box-shadow: 0 0 0 0 rgba(204,169,44, 0.9);
        }

        70% {
            transform: scale(1);
            box-shadow: 0 0 0 15px rgba(204,169,44, 0);
        }

        100% {
            transform: scale(0.95);
            box-shadow: 0 0 0 0 rgba(204,169,44, 0);
        }
    }
`

export default GlobalStyle
